import React from 'react';
import Logo from 'images/logo_knarent.svg';
import HeroText from './HeroText.component';
import {
  StyledBackgroundImage,
  StyledIconContainer,
  StyledTextWrapperDesktop,
} from './Hero.styles';

const HeroDesktop = ({ image }) => {
  return (
    <StyledTextWrapperDesktop>
      <StyledBackgroundImage desktop fluid={image}>
        <HeroText desktop />
      </StyledBackgroundImage>
      <StyledIconContainer desktop>
        <Logo />
      </StyledIconContainer>
    </StyledTextWrapperDesktop>
  );
};

export default HeroDesktop;
