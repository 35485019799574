import React from 'react';
import Logo from 'images/logo_knarent.svg';
import HeroText from './HeroText.component';
import { StyledBackgroundImage, StyledIconContainer, StyledTextWrapperMobile } from './Hero.styles';

const HeroMobile = ({ image }) => {
  return (
    <StyledBackgroundImage fluid={image}>
      <StyledIconContainer>
        <Logo />
      </StyledIconContainer>
      <StyledTextWrapperMobile>
        <HeroText />
      </StyledTextWrapperMobile>
    </StyledBackgroundImage>
  );
};

export default HeroMobile;
