import React from 'react';
import MainTemplate from 'templates/MainTemplate.template';
import SEO from 'components/utils/seo';
import Hero from 'components/organisms/hero/HeroContainer.component';

const IndexPage = () => (
  <MainTemplate>
    <>
      <SEO title="Strona Główna" />
      <Hero />
    </>
  </MainTemplate>
);

export default IndexPage;
