import React from 'react';
import { StyledHeroTextWrapper, StyledHeading, StyledParagraph } from './Hero.styles';

const HeroText = (props) => {
  return (
    <StyledHeroTextWrapper {...props}>
      <StyledHeading {...props}>FHU Knarent Artur Knap</StyledHeading>
      <StyledParagraph {...props}>
        Wypożyczalnia sprzętu budowlanego, ogrodniczego <br /> i sprzątającego.
      </StyledParagraph>
      <StyledParagraph {...props}>Szeroki wybór narzędzi. Zadzwoń!</StyledParagraph>
    </StyledHeroTextWrapper>
  );
};

export default HeroText;
