import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import useCurrentWitdh from 'components/utils/useCurrentWitdh';
import { StyledWrapperHero } from './Hero.styles';
import HeroMobile from './HeroMobile.component';
import HeroDesktop from './HeroDesktop.component';

const Hero = () => {
  const {
    backgroundImage: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(relativePath: { eq: "spencer-davis-YEmsw57gYa8-hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `,
  );

  const width = useCurrentWitdh();
  return (
    <StyledWrapperHero>
      {width <= 767 && <HeroMobile image={fluid} />}
      {width > 767 && <HeroDesktop image={fluid} />}
    </StyledWrapperHero>
  );
};

export default Hero;
