import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledWrapperHero = styled.div`
  min-height: calc(100vh - ${({ theme }) => theme.dimensions.navbarHeight});
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  min-height: calc(100vh - ${({ theme }) => theme.dimensions.navbarHeight});
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  opacity: 1 !important;
  background-position: center;
  background-size: cover;

  ${({ desktop }) =>
    desktop &&
    css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
      opacity: 1 !important;
      background-position: center;
      background-size: cover;
      width: 50%;

      @media (max-width: 900px) {
        width: 100%;
        height: 50vh;
      }
    `}
`;

export const StyledIconContainer = styled.div`
  width: 80%;
  margin: 0 auto;

  ${({ desktop }) =>
    desktop &&
    css`
      width: 50%;
    `}
`;

export const StyledTextWrapperMobile = styled.div`
  margin: -2rem 3rem 0;
`;

export const StyledTextWrapperDesktop = styled.div`
  height: 100%;
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

export const StyledHeroTextWrapper = styled.div`
  ${({ desktop }) =>
    desktop &&
    css`
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      margin: 0 8rem;
    `}
`;

export const StyledHeading = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.l};
  line-height: 29px;
  padding-bottom: 3rem;

  ${({ desktop }) =>
    desktop &&
    css`
      font-size: ${({ theme }) => theme.fontSize.xxl};
      line-height: 41px;
    `}
`;

export const StyledParagraph = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  line-height: 23px;
  padding-bottom: 3rem;
  ${({ desktop }) =>
    desktop &&
    css`
      font-size: ${({ theme }) => theme.fontSize.xl};
      line-height: 33px;
    `}
`;
